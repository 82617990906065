import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Helmet} from "react-helmet";

function Layout({children}) {
    return (
        <>
        <section className="section has-background-info-light ">
            <div className="container">
                <Helmet title="Epona Solutions Ltd"/>

                <div className="columns is-mobile is-full">
                    <div className="column">

                    </div>
                    <div className="column has-text-centered is-10">
                        <h1 className="title">Epona Solutions Limited</h1>
                    </div>
                    <div className="column">

                    </div>
                </div>
            </div>
        </section>
        <section className="section">

            <div className="container">{children}</div>

            <footer className="footer container">
                <div className="columns is-centered is-vcentered">
                    <div className="column">

                    </div>
                    <div className="column is-10 has-text-centered">
                        (c) 2022 Epona Solutions Ltd
                    </div>
                    <div className="column">

                    </div>
                </div>
            </footer>
        </section>
        </>
    )
}

ReactDOM.render(
        <Layout>
            <App/>
        </Layout>
,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
