import './App.css';
import './main.sass'

function App() {
    return (
        <div className="App">
            <section className="section has-text-centered">
                <p>
                    We provide services that enable technical teams to deliver customer focused outcomes across
                    Financial
                    Services, Public Sector and Commercial sectors. Services include being responsible for the
                    day-to-day
                    leadership of the governance, frameworks and tools, and acting as coach and mentor to your
                    development
                    communities.</p>
                <br/>
                <p>We have a unique ability to communicate across all levels of a company, whether as peer to developers
                    or with senior managers and directors on technical issues arising from delivery.</p>
                <br/>
                <p>You can trust us to be your technical advisor for large technology transformation projects, which
                    might
                    require software developments, tooling implementation, hardware implementation, vendor selection,
                    RFP processes, transformation agenda, operating systems change and technical upgrades.</p>
                <br/>
                <p>We are often called in to ‘save’ projects in distress whether over budget, over time or poor team
                    working due
                    to lack of governance.</p>
                <br/>
                <p>Our style is one of coaching and mentoring. We are advocates for testing automation and clean code,
                    setting standards and implementing strong technical governance and inspiring teams to ‘do the
                    impossible’. We will work alongside your team and have the ability to get into the detail
                    and devise improvement plans to aid successful delivery.</p>
                <br/>
                <p>We have provided services in fast-paced Agile (Scrum, Kanban, Scaled Agile Framework) as well as
                    Waterfall
                    environments. Always hands-on across full SDLC, genuinely technology agnostic and competent in a
                    variety of coding languages, development tools, methodologies, frameworks and best practices and
                    pro-active in keeping up-to-date with developing technologies.</p>
                <br/>
                <p>One of our biggest strengths is the ability to bridge gaps between business, technical and leadership
                    teams and we are not only able to assist at the lowest level of detail but am also skilled in taking
                    that step back to think strategically and pragmatically.</p>

            </section>
            <section className="section has-text-centered has-background-grey-dark has-text-white">
                <p>We have worked with large global clients, such as : Barclays Bank, HSBC, Deutsche Bank, Experian, Tunstall Healthcare</p>
                <p>as well as small and medium-sized enterprises</p>
            </section>


        </div>
    );
}

export default App;
